<template>
  <div class="bg-white">
    <header class="absolute inset-x-0 top-0 z-50">
      <nav
        class="mx-auto max-w-7xl flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <NuxtLink
          class="flex lg:flex-1 items-center"
          :to="localePath({ name: 'index' })"
        >
          <div class="-m-1.5 p-1.5">
            <span class="sr-only">Quo LLC</span>
            <img
              class="h-12 w-auto rounded"
              src="/logo/quo-logo-80.png"
              alt=""
            />
          </div>
          <span class="font-bold text-gray-900 text-3xl pl-3">Quo</span>
        </NuxtLink>

        <div class="flex flex-1 justify-end items-center">
          <div class="">
            <Menu as="div" class="relative inline-block">
              <div>
                <MenuButton
                  id="layout-button-language-switch"
                  class="inline-flex w-full justify-center py-2"
                >
                  <SvgIcon
                    name="globe-alt"
                    type="outline"
                    class="w-6 h-6 stroke-gray-900"
                  />
                </MenuButton>
              </div>
              <MenuItems
                class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active, close }">
                    <NuxtLink
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      :to="switchLocalePath('en')"
                      ><span @click="close">English</span></NuxtLink
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active, close }">
                    <NuxtLink
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      :to="switchLocalePath('de')"
                      ><span @click="close">Deutsch</span></NuxtLink
                    >
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </nav>
    </header>

    <main class="isolate">
      <div class="relative pt-14">
        <div
          class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style="
              clip-path: polygon(
                74.1% 44.1%,
                100% 61.6%,
                97.5% 26.9%,
                85.5% 0.1%,
                80.7% 2%,
                72.5% 32.5%,
                60.2% 62.4%,
                52.4% 68.1%,
                47.5% 58.3%,
                45.2% 34.5%,
                27.5% 76.7%,
                0.1% 64.9%,
                17.9% 100%,
                27.6% 76.8%,
                76.1% 97.7%,
                74.1% 44.1%
              );
            "
          />
        </div>
        <div class="py-24 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl">
              <slot></slot>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] pointer-events-none"
          aria-hidden="true"
        >
          <div
            class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style="
              clip-path: polygon(
                74.1% 44.1%,
                100% 61.6%,
                97.5% 26.9%,
                85.5% 0.1%,
                80.7% 2%,
                72.5% 32.5%,
                60.2% 62.4%,
                52.4% 68.1%,
                47.5% 58.3%,
                45.2% 34.5%,
                27.5% 76.7%,
                0.1% 64.9%,
                17.9% 100%,
                27.6% 76.8%,
                76.1% 97.7%,
                74.1% 44.1%
              );
            "
          />
        </div>
      </div>
    </main>

    <footer class="z-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div class="md:grid md:grid-cols-4 md:gap-8">
          <div class="space-y-8">
            <NuxtLink class="flex" to="/">
              <img
                class="h-7 rounded"
                src="/logo/quo-logo-80.png"
                alt="Quo LLC"
              />
              <span class="font-bold text-gray-900 text-lg pl-3">Quo</span>
            </NuxtLink>

            <p class="text-sm leading-6 text-gray-600">
              Keep all your places in one place.
            </p>
            <div class="flex space-x-6">
              <NuxtLink
                v-for="item in footerNavigation.social"
                :key="item.name"
                :to="item.to"
                class="text-gray-400 hover:text-gray-600"
              >
                <span class="sr-only">{{ item.name }}</span>
                <SvgIcon
                  :name="item.iconName"
                  type="solid"
                  class="h-6 w-6 text-gray-400 hover:text-gray-600"
                  aria-hidden="true"
                />
              </NuxtLink>
            </div>
          </div>
          <div
            class="mt-16 grid grid-cols-2 sm:grid-cols-3 gap-8 md:col-span-3 md:mt-0"
          >
            <div class="">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                Products
              </h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in footerNavigation.products" :key="item.name">
                  <NuxtLink
                    :to="item.to"
                    class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >{{ item.name }}</NuxtLink
                  >
                </li>
              </ul>
            </div>
            <div class="">
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                Company
              </h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in footerNavigation.company" :key="item.name">
                  <NuxtLink
                    :to="item.to"
                    class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >{{ item.name }}</NuxtLink
                  >
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-sm font-semibold leading-6 text-gray-900">
                Legal
              </h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in footerNavigation.legal" :key="item.name">
                  <NuxtLink
                    :to="item.to"
                    class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >{{ item.name }}</NuxtLink
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-12 border-t border-gray-200 pt-8 sm:mt-16 lg:mt-20">
          <p class="text-xs leading-5 text-gray-500">
            &copy; 2023 Quo LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
const { $metric } = useNuxtApp()

const iconStore = useIconStore()

const switchLocalePath = useSwitchLocalePath()
const localePath = useLocalePath()

const footerNavigation = {
  products: [
    { name: 'Quo for individuals', href: '#' },
    { name: 'Quo for organizations (soon)', href: '#' },
  ],
  company: [
    {
      name: 'Team',
      to: localePath({
        name: 'company-team',
      }),
    },
    {
      name: 'Contact',
      to: localePath({
        name: 'support',
      }),
    },
  ],
  legal: [
    {
      name: 'Terms of Service',
      to: localePath({
        name: 'legal-terms-of-service',
      }),
    },
    {
      name: 'Privacy Policy',
      to: localePath({
        name: 'legal-privacy-policy',
      }),
    },
    {
      name: 'Imprint',
      to: localePath({
        name: 'legal-imprint',
      }),
    },
  ],
  social: [
    {
      name: 'X',
      to: 'https://x.com/quo_space',
      iconName: 'x-corp',
    },
    {
      name: 'Pinterest',
      to: 'https://www.pinterest.com/quospace',
      iconName: 'pinterest',
    },
    {
      name: 'LinkedIn',
      to: 'https://www.linkedin.com/company/quo-space/',
      iconName: 'linkedin',
    },
  ],
}

await useAsyncData('icons', async () => {
  const [icons] = await Promise.all([iconStore.fetchIcons()])
  return { icons }
})

if (process.client) {
  await $metric({ key: 'PAGE_VIEW' })
}
</script>
